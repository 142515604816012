<template>
  <div class="merchant-form">
    <div class="padding flex1">
      <Form readonly :model="form" :labelWidth="120" :rules="validationRules" ref="form">
        <FormItem label="行业类别" prop="category">
          {{ form.category }}
        </FormItem>
        <FormItem label="店铺名称" prop="merchantName">
          {{ form.merchantName }}
        </FormItem>
        <FormItem label="店铺地址" prop="address">
          {{ form.address }}
        </FormItem>
        <FormItem label="老板姓名" prop="bossName">
          {{ form.bossName }}
        </FormItem>
        <FormItem label="电话" prop="mobile">
          {{ form.mobile }}
        </FormItem>
        <FormItem label="营业执照编号" prop="businessLicense">
          {{ form.businessLicense }}
        </FormItem>
        <FormItem label="营业执照" prop="businessLicense">
          <ImagePreview :width="70" :borderRadius="4" :datas="form.businessLicensePic" @click="openPreview"/>
        </FormItem>
        <FormItem label="身份证正面" prop="idCardHeads">
          <ImagePreview :width="70" :borderRadius="4" :datas="'/api'+form.idCardHeads" @click="openPreview"/>
        </FormItem>
        <FormItem label="身份证反面" prop="idCardAgainst">
          <ImagePreview :width="70" :borderRadius="4" :datas="'/api'+form.idCardAgainst" @click="openPreview"/>
        </FormItem>
        <FormItem label="银行名称" prop="bankName">
          {{ form.bankName }}
        </FormItem>
        <FormItem label="开户行" prop="bankName">
          {{ form.openingBank }}
        </FormItem>
        <FormItem label="银行卡号" prop="bankName">
          {{ form.bankCardNo }}
        </FormItem>
        <FormItem label="银行卡开户人" prop="bankName">
          {{ form.cardUserName }}
        </FormItem>
      </Form>
    </div>
    <div class="h-modal-footer">
      <!--      <Button @click="auditShopping" color="yellow" icon="h-icon-warn" v-if="form.purchaseService==1">直购审核</Button>-->
      <Button @click="audit" color="yellow" icon="h-icon-info" v-if="form.status==0">注册审核</Button>
      <Button @click="remove" color="red" icon="fa fa-close">删除</Button>
      <Button @click="toForm" color="primary" icon="fa fa-edit">编辑</Button>
      <Button @click="toForm" color="green" icon="fa fa-calendar">续期</Button>
    </div>
  </div>
</template>

<script>
import Merchant from "@/api/Merchant";

export default {
  name: "MerchantForm",
  props: {
    entity: Object,
  },
  data() {
    return {
      isLoading: false,
      form: {
        id: null,
        merchantName: '',
        address: '',
        category: '',
        mobile: '',
        bossName: '',
        idCardHeads: '',
        idCardAgainst: '',
        businessLicense: '',
        businessLicensePic: '',
        bankName: '',
        cardUserName: '',
        bankCardNo: '',
        openingBank: '',
        purchaseService: '',
      },
      validationRules: {
        required: ['merchantName', 'address', 'category', 'mobile', 'bossName'],
        mobile: ['mobile']
      }
    }
  },
  methods: {
    toForm() {
      this.$Modal({
        title: '商户信息',
        closeOnMask: false,
        middle: true,
        component: {
          vue: (resolve) => {
            require(['./MerchantForm'], resolve);
          },
          datas: {
            entity: this.entity
          }
        }
      });
    },
    openPreview(data) {
      this.$ImagePreview(data);
    },
    remove() {
      this.$Confirm('确认删除?', '系统提示').then(() => {
        Merchant.remove(this.entity.id).then(({data}) => {
          this.$emit('success');
          this.$emit('close');
        });
      });
    },
    audit() {
      Merchant.audit(this.entity.id).then(({data}) => {
        this.$emit('success');
        this.$emit('close');
      });
    }
  },
  created() {
    Utils.copyObj(this.form, this.entity);
  }
}
</script>

<style scoped lang="less">
.merchant-form {
  width: 40vw;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
</style>
